.appointment-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  padding: 8px;
  color: white;
  line-height: initial;
  font-size: 0.85rem;
}
.appointment-title {
  @extend .appointment-text;
  padding: 4px 0px 0px 8px;
  font-weight: bold;
  flex: 1;
  font-size: 0.95rem;
}

.appointment-users {
  text-align: center;
}

div.MuiAvatarGroup-avatar {
  width: 32px;
  height: 32px;
  font-size: 0.75rem;
}
