.logo-img {
  width: 60%;
  max-width: 360px;
}

.login-button {
  background-color: white !important;
  box-shadow: 0px 16px 60px rgba(78, 79, 114, 0.08);
  width: 70%;
  max-width: 360px;
  align-self: center;
}

.login-button-text {
  color: #4285f4;
  font-weight: bolder;
  text-transform: none;
}

.login-button-icon {
  height: 25px;
  width: 25px;
  margin-right: 10px;
  align-items: center;
}
