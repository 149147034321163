@import "../../Styles.scss";

.user-pills-div {
  @extend .flex-row-center;
  gap: 5px;
  flex-wrap: wrap;
  margin: 10px 0px 5px 0px;
  flex: 1;
  max-height: calc(var(--app-height) - 340px);
  overflow: auto;
}

.buttons-div {
  @extend .flex-row-center;
  padding: 10px 15px;
}

.icons-div {
  @extend .flex-row-center;
  flex: 1;
  justify-content: flex-start;
  margin-right: 10px;
  gap: 5px;
}

.going-chip {
  position: absolute;
  right: 2.5em;
  top: 0.6em;
  display: block;
}
