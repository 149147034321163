@import "../../Styles.scss";

div.MuiFormGroup-row {
  justify-content: center;
}

.user-filter-div {
  width: 100%;
  //align-items: flex-start;
}
