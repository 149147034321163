div[class*="MuiListItemIcon-root"] {
    min-width: 36px;
}

div.MuiDrawer-paper {
    background-image: none !important;
}

.session-search-icon {
    margin: 2px 2px 2px -2px;
}

.session-search-typography {
    align-self: center;
}