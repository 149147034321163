@import "../../Styles.scss";

.half-container {
    overflow: auto;
    gap: 5px;
}

.buttons-container {
    margin-top: 10px;
    gap: 20px;
}

.section-button-group {
    margin-left: auto;
    gap: 5px;
}

.bottom-fade-div {
    background: linear-gradient(to top, $dark-blue-bg-color calc(50px), transparent 100%);
    height: 200px;
    width: 100%;
    position: fixed;
    bottom: 0
}