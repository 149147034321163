$dark-color: #282c34;
$dark-green: #00251a;
$my-teal: rgb(33, 48, 48);
$my-green: #004d40;
$orangeville: #e17055;
$dark-blue-bg-color: #19182F;
$action-button-color: #6734F8;
// $my-green: #2bbbad;

.MuiTab-root {
  text-transform: capitalize !important;
  font-size: 1rem !important;
}

.MuiAvatar-fallback,
.MuiAvatarGroup-avatar {
  color: #e0e0e0 !important;
}

.MuiAvatarGroup-avatar {
  border: 1.5px solid rgba(255, 255, 255, 0.5) !important;
}

.top-right {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  z-index: 9999;
}

.less-padding {
  margin: -5px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row-center {
  @extend .flex-row;
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.align-end {
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
}

.full-size-div {
  @extend .flex-column;
  width: 100vw;
  height: var(--app-height);
  justify-items: center;
  justify-content: space-around;
}

.action-button {
  background-color: $action-button-color !important;
  flex: 1;
}

.MuiImageListItemBar-title {
  text-align: center;
}

.MuiImageList-root {
  cursor: pointer;
}

.MuiPopover-paper.MuiPaper-elevation8 {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  background-color: #272537 !important
}

.resource-header div div {
  color: white !important;
}

#scheduler-top-date-picker-div div button {
  margin-right: -8px;
}

// [class*="Layout-popover"], [class*="Layout-popover"].[class*="Layout-popover"], [class*="Layout-popover"].[class*="Layout-popover"] {
//   background-color: #272537;
// }

:export {
  darkColor: $dark-color;
  actionButtonColor: $action-button-color;
  darkBlueBGColor: $dark-blue-bg-color
}