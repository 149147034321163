@import "../../Styles.scss";

.item-div {
  @extend .flex-row-center;
  align-items: center;
  gap: 4px;
}

.smaller {
  font-size: small;
}

.small {
  font-size: small;
}

.medium {
}

.large {
  font-size: x-large;
}
