@import "../../Styles.scss";

.bottom-app-bar {
  @extend .flex-row;
  justify-content: space-around;
  width: 100%;
}

.icon-div {
  @extend .flex-column;
  place-items: center;
  margin-top: -5px;
}
