.unselected {
  background: gray;
}

div.RootBase-root-3 {
  position: sticky;
  left: 20px;
  top: 0;
}

td[class*="groupedByDate"] {
  border-top: 0px;
}
